<template>
    <header>
        <div class="flex mx-auto max-w-7xl items-center">
            <div class="flex m-4 gap-4 items-center justify-between w-full">
                <NuxtLink :to="localePath({ name: 'index' })" class="flex items-center gap-4">
                    <IconLogoAugwa class="fill-white size-12" alt="Augwa" />
                    <div class="title-3">Augwa</div>
                </NuxtLink>

                <div>
                    <UHorizontalNavigation class="hidden sm:flex" :links="menuItems" :ui="{ active: 'text-white', inactive: 'text-gray-700'}" />

                    <UDropdown :items="menuItems" class="sm:hidden">
                        <UIcon name="heroicons:bars-3-20-solid" class="size-8" />
                    </UDropdown>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import type { DropdownItem } from '#ui/types'

const localePath = useLocalePath()

const menuItems: DropdownItem[][] = [
    [
        { label: 'Home', to: localePath({ name: 'index' }) },
        { label: 'Rate Calculator', to: localePath({ name: 'calculators-find-the-right-rate-for-your-business' }) },
        { label: 'Blog', to: `https://www.augwa.com/blog` },
    ],
]
</script>